<template>
  <div class="fill-height verifyWrapper">
    <template v-if="debug">
        User Account Verification: {{states.verification}}
        <!--
        <v-btn @click="verifyURL">verifyURL</v-btn>
        <v-btn @click="verified">verified</v-btn>
        -->

        <v-card width="600" v-if="states.verification!='idle'">
            <v-card-text>
                Account Verification Status: {{states.verification}}
                <hr>
                Message: {{message}}
            </v-card-text>
            <v-card-actions class="justify-center">
                <router-link v-if="states.verification!='success'" :to="'/'">
                    <v-btn>Home</v-btn>
                </router-link>
                <router-link v-else :to="'/login'">
                    <v-btn>Login</v-btn>
                </router-link>
            </v-card-actions>
        </v-card>
    </template>

    <v-row v-if="ui.busy==false" align="center" justify="center" no-gutters>
        <v-col class="fill-height d-flex justify-center">
            <v-sheet color="#fafafa" class="text-center d-flex flex-column align-center justify-center fill-height"
                :class="{['pt-12']:states.verification=='success'}"
                :style="{'width': $vuetify.breakpoint.smAndDown ? '80%' : '35%'}">
                <img :src="graphicAssets.StateIcon" class="pb-6"/>
                <template v-if="states.verification=='failed' || states.verification=='invalid_link'">
                    <h2 :class="['py-6', $vuetify.breakpoint.xsOnly ? 'text-h6' : 'text-h5']"><str index="authenticator.view.verify.account_activation_failure"/></h2>
                    <h2 :class="['py-6', $vuetify.breakpoint.xsOnly ? 'text-h6' : 'text-h5']"><str index="authenticator.view.verify.try_again_or_contact_us"/></h2>
                    <router-link :to="'/signup'">
                        <v-btn class="cta" tile dark depressed><str index="authenticator.view.verify.to_sign_up"/></v-btn>
                    </router-link>
                </template>
                <template v-else-if="states.verification=='success'">
                    <h2 :class="['py-6', $vuetify.breakpoint.xsOnly ? 'text-h6' : 'text-h5']"><str index="authenticator.view.verify.account_activation_success"/></h2>
                    <router-link :to="'/login'">
                        <v-btn class="cta mt-6" tile dark depressed><str index="authenticator.view.verify.start_now"/></v-btn>
                    </router-link>
                </template>
            </v-sheet>
        </v-col>
    </v-row>
    <v-dialog width="250" v-model="ui.busy" persistent>
        <v-card>
            <v-card-text>
                <str index="authenticator.verification.verifying_account"/>
            </v-card-text>
        </v-card>
    </v-dialog>

  </div>
</template>

<script>
import Amplify, { Auth, Hub } from 'aws-amplify';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import awsconfig from '@//aws-exports';
import SuccessIcon from '@/assets/images/UI/icon_success.png'
import FailIcon from '@/assets/images/UI/icon_fail.png'
Amplify.configure(awsconfig);

export default {
    name: "verification",
    created: function(){
        this.verifyURL()
    },
    data: function(){
        return {
            debug: false,
            ui: {
                busy: false
            },
            config: {
                verificationServer: process.env.VUE_APP_ENV!=='production' ? 'https://staging-api.listerine.learning-hub.ca' : '/api',
                pinLength: 6
            },
            username: null,
            user_id: null,
            verificationCode: null,
            states: {
                verification: 'idle'
            },
            message: null
        }
    },
    methods: {
        verifyURL: async function(){
            this.ui.busy = true
            this.states.verification = 'busy'
            let server = this.config.verificationServer
            let code = this.code
            let response
            try {
                response = await this.sendRequest('get',`${server}/verification/${code}`)             
                this.username = response.data.email
                this.user_id = response.data.user_id
                this.verificationCode = response.data.code.toString()
                await this.confirmSignUp()
            } catch (err) {
                console.error('verifyURL:error',err)
                this.states.verification = 'invalid_link'
            }
            this.ui.busy = false

        },
        async confirmSignUp(){
            let username = this.username
            let verificationCode = this.verificationCode
            try {
                let result = await Auth.confirmSignUp(username, verificationCode);
                await this.verified()
                //console.log('confirm signUp: success',result)
                this.states.verification = 'success'
            } catch (err) {
                console.error('confirm signUp: error',err)
                this.states.verification = 'failed'
                this.message = err.message
            }
        },
        async verified(){
            let server = this.config.verificationServer
            let response
            try {
                response = await this.sendRequest('put', `${server}/verification/${this.user_id}`)               
                //console.log(response)u
            } catch (err) {
                console.error('verified: error',err)
            }
        }
    },
    computed: {
        code: function(){
            return this.$route.params.code
        },
        graphicAssets: function(){
            return {
                StateIcon: this.states.verification==='success' ? SuccessIcon : FailIcon
            }
        }
    }
}
</script>

<style scoped lang="scss">

.verifyWrapper {
    height: fit-content;
    min-height: calc(100vh - 172px);
    padding: 24px 0;

    > div {
        > div {
            > div {
                padding: 24px;
            }
        }
    }
}

.cta {
    background-color: $primary-light-1 !important;
    text-transform: capitalize;
}
</style>
